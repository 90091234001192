var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"plan-chart",class:{'pt-15': _vm.plan === 0 }},[_c('Bar',{attrs:{"bars":[
      {
        start: 0,
        stop: _vm.fact + _vm.predict,
        class: 'text-right',
        background: '#B9C3D8',
        'z-index': 10,
        title: 'Выставлено счетов на'
      },
      {
        start: 0,
        stop: _vm.fact,
        class: 'text-right',
        background: 'multicolor',
        'z-index': 20,
        title: 'План выполнен на'
      }
    ],"max":_vm.plan,"pointerOffset":_vm.pointerOffset,"pointerCursor":_vm.isPresentMonth,"pointerTbuCursor":"","pointerTbuValue":_vm.minPlan,"pointerTbuOffset":_vm.pointerTbuOffset}},[(_vm.isPresentMonth)?_c('span',{attrs:{"slot":"pointer-text"},slot:"pointer-text"},[_vm._v(" "+_vm._s(_vm.currentDate)+" "),(_vm.sumMargin < 0)?[_c('br'),_vm._v("Опережение "),_c('span',{staticClass:"currency"},[_vm._v(_vm._s(_vm._f("money")((_vm.sumMargin * -1))))])]:[_c('br'),_vm._v("Отставание "),_c('span',{staticClass:"currency"},[_vm._v(_vm._s(_vm._f("money")(_vm.sumMargin)))])]],2):_vm._e()]),_c('div',{staticClass:"plan-chart__labels mt-15"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"labels__left"},[_c('div',{staticClass:"chart-label chart-label__paid"},[_c('span',{staticClass:"icon",style:({ background: _vm.$options.filters.barcolor(_vm.factPercent, _vm.pointerOffset) })}),_c('span',{staticClass:"chart-label__value currency"},[_vm._v(_vm._s(_vm._f("money")(_vm.fact)))]),_vm._v(" - Выполнено ")]),_c('div',{staticClass:"chart-label chart-label__predict"},[_c('span',{staticClass:"icon"}),_c('span',{staticClass:"chart-label__value currency"},[_vm._v(_vm._s(_vm._f("money")(_vm.predict)))]),_vm._v(" - Выставленные счета ")])]),_c('div',{staticClass:"labels__right text-right"},[_c('div',{staticClass:"chart-label chart-label__remaining"},[_c('span',{staticClass:"icon"}),_vm._v("Необходимо - "),_c('span',{staticClass:"chart-label__value currency"},[_vm._v(_vm._s(_vm._f("money")(_vm.plan)))])]),_c('div',{staticClass:"chart-label chart-label__remaining"},[_vm._v("Осталось - "),_c('span',{staticClass:"chart-label__value currency"},[_vm._v(_vm._s(_vm._f("money")(_vm.rest)))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }