<template>
  <div
    class="rx-datepicker"
    :class="{ opened: isOpen }"
    v-on-clickaway="close"
  >
    <div
      class="rx-datepicker__label"
      @click="showCalendar"
    >
      <SpriteIcon icon="calendar" class="mr-10" style="height: 21px;" />
      {{ formattedValue }}
      <SpriteIcon icon="check" class="ml-10" style="height: 20px;" />
    </div>
    <div v-if="isOpen" class="rx-datepicker__calendar">
      <div class="rx-datepicker__calendar-wrapper">
        <div class="rx-datepicker__year">
          <div class="rx-datepicker__year-select">
            <div
              class="rx-datepicker__year-minus"
              @click="previousYear()"
            >
              <Arrow direction="left" :border="false" />
            </div>
            <div class="rx-datepicker__year-value">
              {{ selectedYear }}
            </div>
            <div
              class="rx-datepicker__year-plus"
              @click="nextYear()"
            >
              <Arrow direction="right" :border="false" />
            </div>
          </div>
        </div>
        <div class="rx-datepicker__month">
          <Loader v-if="isLoading" />
          <div v-else-if="error" class="text-danger">{{ error }}</div>
          <div v-else class="rx-datepicker__month-list">
            <perfect-scrollbar
              :options="{ wheelPropagation: false }"
            >
              <div
                class="rx-datepicker__item"
                v-for="month in months"
                :key="month.timestamp"
                :class="{'selected': month.isSelected}"
                @click="selectMonth(month.timestamp)"
              >
                <div class="rx-datepicker__item-icon">
                  <span
                    class="dot-icon mr-15"
                    :style="month.plan && month.fact > 0 ? { background: $options.filters.barcolor(month.fact, month.plan)} : undefined"
                  ></span>
                </div>
                <div class="rx-datepicker__item-body">
                  <div class="rx-datepicker__item-title">{{ month.name }}</div>
                  <div
                    v-if="month.plan"
                    class="rx-datepicker__item-value"
                  >
                    <span class="currency">{{ month.fact | money }}</span> из <span class="currency">{{ month.plan | money }}</span>
                  </div>
                  <div
                    v-else
                    class="rx-datepicker__item-link dotted-blue mt-0"
                    role="button"
                  >
                    Назначить план
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '@/components/ui/Arrow'
import { directive as onClickaway } from 'vue-clickaway'

export default {
  name: 'DateSelect',
  components: {
    Arrow
  },
  directives: {
    onClickaway: onClickaway
  },
  computed: {
    date: {
      get: function () {
        return this.$store.state.sale.date.date
      },
      set: function (newValue) {
        this.$store.dispatch('sale/date/updateDate', newValue)
        this.$emit('date-update')
      }
    },
    isOpen () {
      return this.visibleCalendar
    },
    formattedValue () {
      if (!this.selectedDate) {
        return null
      }

      const date = this.$moment(this.date)
      return date.format('MMMM YYYY')
    }
  },
  data () {
    return {
      isLoading: true,
      error: '',
      months: [],
      selectedDate: null,
      selectedYear: null,
      selectedMonth: null,
      visibleCalendar: false
    }
  },
  created () {
    this.setDate(this.date)
  },
  methods: {
    async fetchData () {
      this.isLoading = true

      try {
        await this.$api.query(`/sale/date-select?year=${this.selectedYear}`).then(response => {
          if (response && response.length) {
            this.setMonths(response)
          }
        })
      } catch (e) {
        this.error('Ошибка получения данных')
      }

      this.isLoading = false
    },
    showCalendar () {
      if (this.isOpen) {
        return this.close()
      }

      this.visibleCalendar = true
      this.fetchData()
    },
    close () {
      this.visibleCalendar = false
    },
    previousYear () {
      this.selectYear(-1)
    },
    nextYear () {
      this.selectYear(1)
    },
    selectYear (increment) {
      if (increment > 0) {
        this.selectedYear += 1
      } else {
        this.selectedYear -= 1
      }

      this.fetchData()
    },
    selectMonth (timestamp) {
      this.date = new Date(timestamp)
    },
    isSelectedMonth (date) {
      return (
        this.selectedDate &&
        this.$moment(this.selectedDate).format('YYYY') === this.$moment(date).format('YYYY') &&
        this.$moment(this.selectedDate).format('MM') === this.$moment(date).format('MM')
      )
    },
    setDate (date) {
      this.selectedDate = date
      this.selectedYear = this.selectedDate.getFullYear()
      this.selectedMonth = this.selectedDate.getMonth()
    },
    setMonths (data) {
      const months = []

      data.forEach((el, i) => {
        const humanMonthNumber = i + 1
        const timestamp = this.$moment(`01-${humanMonthNumber}-${this.selectedYear}`, 'DD-MM-YYYY').valueOf()
        months.push({
          name: this.$moment(humanMonthNumber, 'MM').format('MMMM'),
          timestamp: timestamp,
          isSelected: this.isSelectedMonth(timestamp),
          fact: el ? el.fact : null,
          plan: el ? el.plan : null
        })
      })

      this.months = months
    }
  }
}
</script>

<style>
.rx-datepicker {
  position: relative;
}
.rx-datepicker .rx-datepicker__label {
  color: var(--title-color);
  font-weight: 600;
  cursor: pointer;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.rx-datepicker .rx-datepicker__label:hover {
  color: var(--text-color);
}
.rx-datepicker.opened .rx-datepicker__label .sprite_icon.check {
  transform: rotate(180deg);
}
.rx-datepicker__calendar {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  z-index: 101;
  padding: 20px 20px;
  width: 265px;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(185, 195, 216, 0.5);
  border-radius: 10px;
}
.rx-datepicker__year-select {
  background: var(--bg-color);
  border-radius: 7px;
  padding: 5px 5px;
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rx-datepicker__year-value {
  text-align: center;
  color: var(--title-color);
  font-weight: 600;
}
.rx-datepicker__month {
  margin-top: 15px;
}
.rx-datepicker__item {
  display: flex;
  cursor: pointer;
  margin-top: 15px;
}
.rx-datepicker__item.empty {
  cursor: initial;
}
.rx-datepicker__item:first-child {
  margin-top: 0;
}
.rx-datepicker__item-title {
  color: var(--text-color);
  font-weight: 600;
  margin-bottom: 2px;
  text-transform: capitalize;
}
.rx-datepicker__item.selected .rx-datepicker__item-title,
.rx-datepicker__item:not(.empty):hover .rx-datepicker__item-title {
  color: var(--title-color);
}
.rx-datepicker__item-value {
  color: var(--text-color);
}
.rx-datepicker__month,
.rx-datepicker .ps {
  height: 285px;
}
</style>
