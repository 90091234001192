<template>
  <div
    class="plan-chart"
    :class="{'pt-15': plan === 0 }"
  >
    <Bar
      :bars="[
        {
          start: 0,
          stop: fact + predict,
          class: 'text-right',
          background: '#B9C3D8',
          'z-index': 10,
          title: 'Выставлено счетов на'
        },
        {
          start: 0,
          stop: fact,
          class: 'text-right',
          background: 'multicolor',
          'z-index': 20,
          title: 'План выполнен на'
        }
      ]"
      :max="plan"
      :pointerOffset="pointerOffset"
      :pointerCursor="isPresentMonth"
      pointerTbuCursor
      :pointerTbuValue="minPlan"
      :pointerTbuOffset="pointerTbuOffset"
    >
      <span
        v-if="isPresentMonth"
        slot="pointer-text"
      >
        {{ currentDate }}
        <template v-if="sumMargin < 0"><br>Опережение <span class="currency">{{ (sumMargin * -1) | money }}</span></template><template v-else><br>Отставание <span class="currency">{{ sumMargin | money }}</span></template>
      </span>
    </Bar>

    <div class="plan-chart__labels mt-15">
      <div class="d-flex justify-content-between">
        <div class="labels__left">
          <div class="chart-label chart-label__paid">
            <span
              class="icon"
              :style="{ background: $options.filters.barcolor(factPercent, pointerOffset) }"
            ></span>
            <span class="chart-label__value currency">{{ fact | money }}</span> - Выполнено
          </div>
          <div class="chart-label chart-label__predict">
            <span class="icon"></span>
            <span class="chart-label__value currency">{{ predict | money }}</span> - Выставленные счета
          </div>
        </div>
        <div class="labels__right text-right">
          <div class="chart-label chart-label__remaining"><span class="icon"></span>Необходимо - <span class="chart-label__value currency">{{ plan | money }}</span></div>
          <div class="chart-label chart-label__remaining">Осталось - <span class="chart-label__value currency">{{ rest | money }}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from '@/components/sale/Bar'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'PlanChart',
  components: { Bar },
  props: {
    plan: {
      type: Number,
      default: 0
    },
    minPlan: {
      type: Number,
      default: 0
    },
    fact: {
      type: Number,
      default: 0
    },
    predict: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters('sale/date', ['isPresentMonth']),
    currentDate () {
      return moment().locale('ru').format('D MMMM')
    },
    pointerOffset () {
      if (this.isPresentMonth) {
        const lastDay = moment().endOf('month').format('D') - 1
        const curDay = moment().format('D') - 1

        return Math.floor(curDay / lastDay * 100)
      } else {
        return 100
      }
    },
    pointerTbuOffset () {
      return this.minPlan && this.plan ? Math.floor(this.minPlan / this.plan * 100) : 0
    },
    sumMargin () {
      if (!this.plan || !this.pointerOffset) {
        return 0
      }

      return this.plan * this.pointerOffset / 100 - this.fact
    },
    rest () {
      if (this.fact >= this.plan) {
        return 0
      } else {
        return this.plan - this.fact
      }
    },
    factPercent () {
      return this.fact && this.plan ? Math.floor(this.fact / this.plan * 100) : 0
    }
  }
}
</script>

<style>
.plan-chart {
  padding-top: 74px;
}

.chart-label {
  font-weight: 400;
  color: var(--text-color);
  margin-bottom: 10px;
}

.chart-label:last-child {
  margin-bottom: 0;
}

.chart-label .icon {
  margin-right: 10px;
  margin-bottom: 1px;
  min-width: 6px;
  min-height: 6px;
  background: #fff;
  border-radius: 3px;
  display: inline-block;
}

.chart-label__paid .icon {
  background: var(--green-color);
}

.chart-label__predict .icon {
  background: #B9C3D8;
}

.chart-label__remaining .icon {
  background: #D5DCEA;
}

.bar-line__total .icon {
  background: var(--bg-color);
}
.chart-label__value {
  font-weight: 600;
  color: var(--title-color);
}
</style>
